
<script>
import CalendarApiService from "@/services/calendarService";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
  },
  data() {
    return {
      showModal: false,
      title: "Edit job type",
      jobTypeForm: {
        description: null
      },
      isJobTypeFormSubmit: false,
      jobTypeRef: null
    };
  },
  created(){
    this.$bus.$on('open-edit-job-type-dialog', this.openEditJobTypeDialog)   
  },
  beforeDestroy(){
    this.$bus.$off('open-edit-job-type-dialog', this.openEditJobTypeDialog)  
  },  
  computed: {

  },
  methods: {
    openEditJobTypeDialog: function(jobType){
      this.jobTypeRef = jobType;
      this.showModal = true;
      this.jobTypeForm.idPublic = jobType.idPublic;
      this.jobTypeForm.name = jobType.name;
    },
    closeModal(){
      this.showModal = false;
      this.isJobTypeFormSubmit = false;
      this.jobTypeForm = {
        idPublic: null,
        name: null
      };
    },
    jobTypeFormSubmit(){
      this.isJobTypeFormSubmit = true;
      this.$v.jobTypeForm.$touch()

      if (!this.$v.jobTypeForm.$invalid) {
        //save form
        CalendarApiService.updateJobType(this.jobTypeForm).then(() => {
          this.jobTypeRef.name = this.jobTypeForm.name;

          this.closeModal();
        })
      }
    }
  },
  validations: {
    jobTypeForm: {
      name: {required}
    }
  } 
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="$t('general.editJobType')"
    title-class="text-black font-18"
    hide-footer
    body-class="p-3"
    size="sm"
  >
    <b-form @submit.prevent="jobTypeFormSubmit">   
      <div class="row">
        <b-form-group style="position:relative;" class="col-12">
          <label>{{$t('general.name')}}</label>
          <b-form-input 
            v-model="jobTypeForm.name"
            :class="{ 'is-invalid': isJobTypeFormSubmit && $v.jobTypeForm.name.$error }"
            :placeholder="$t('general.name')">
          </b-form-input>
          <div v-if="isJobTypeFormSubmit && $v.jobTypeForm.name.$error" class="invalid-feedback">
            <span v-if="!$v.jobTypeForm.name.required">{{$t('general.valueRequired')}}</span>
          </div>
        </b-form-group>
        
      </div>
      <div class="text-right pt-3">
        <b-button variant="light" @click="closeModal">{{$t('general.close')}}</b-button>
        <b-button type="submit" class="ml-1" variant="success" >{{$t('general.save')}}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>