<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import CalendarApiService from "@/services/calendarService";
import { required } from "vuelidate/lib/validators";
import JobTypeDetailModal from "./modals/jobTypeDetail"
import DeletionModal from "../../../../components/widgets/deletion-modal.vue"

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "workOrderTypes",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    JobTypeDetailModal,
    DeletionModal
  },
  data() {
    return {
      title: "Job types",
      isLoading: false,
      items: [
        {
          text: "progress",
          href: "/progress",
        },
        {
          text: "",
          active: true,
        },
      ],
      jobTypes: [],
      jobTypeSubmit: false,
      jobTypeForm: {
        name: null
      },
      jtIdPublic: null,
      jtIndex: null
    };
  },
  created (){
    //Load progress
    this.isLoading = true;
    CalendarApiService.getAllJobTypes().then((response) =>{
      this.jobTypes = response.data.data;
      this.isLoading = false;
    });
  },
  mounted() {},
  methods: {
    addJobType(){

    },
    showDeletionModal(idPublic, index) {
      this.$refs.jobTypeDeleteModal.show().then(() => {
        this.isLoading = true;
        CalendarApiService.deleteJobType(idPublic).then(() => {
          this.jobTypes.splice(index, 1);
          this.isLoading = false;
        })
      })
    },
    submitJobType(){
      this.jobTypeSubmit = true;
      this.$v.jobTypeForm.$touch()

      if (!this.$v.$invalid) {
        this.isLoading = true;
        CalendarApiService.createJobType(this.jobTypeForm.name).then((response) => {
          let typeJob = response.data.data;
          let model = {
            idPublic: typeJob.idPublic,
            name: this.jobTypeForm.name
          }
          this.jobTypes.push(model);
          this.isLoading = false;

          this.jobTypeForm.name = null;
          this.jobTypeSubmit = false;
        })
      }       
    },
    editJt(jobType){
      this.$bus.$emit('open-edit-job-type-dialog', jobType);
    }
  },
  validations: {
    jobTypeForm: {
      name: { required }
    }
  }  
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('general.workOrderTypes')" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p class="card-title-desc mb-4">
                  {{$t('general.workOrderTypesList')}}
                </p> 
                <form @submit.prevent="submitJobType" style="display:flex; align-items:flex-end;">
                  <div class="form-group"  style="position:relative;">
                    <b-form-input 
                      :class="{ 'is-invalid': jobTypeSubmit && $v.jobTypeForm.name.$error }" 
                      style="max-width:300px;" 
                      v-model="jobTypeForm.name" 
                      :placeholder="$t('general.name')">
                    </b-form-input>
                    <div v-if="jobTypeSubmit && $v.jobTypeForm.name.$error" class="invalid-tooltip">
                      <span v-if="!$v.jobTypeForm.name.required">{{$t('general.requiredValue')}}</span>
                    </div>
                  </div>                 
                  <b-form-group>
                    <b-button type="submit" class="ml-3" variant="primary" >{{$t('general.add')}}</b-button>
                  </b-form-group>                  
                </form>
              </div>
            </div>

            <div ref="progressTable" class="table-responsive">
              <loading :active.sync="isLoading" 
                :can-cancel="false"
                color='#f1b44c'
                :width=70
                :height=70
                loader='dots'
                :is-full-page="false">
              </loading>              
              <table class="table mb-0 table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>{{$t('general.name')}}</th>
                    <th style="width:100px; text-align:center;">{{$t('general.action')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(jt, index) in jobTypes" :key="index">
                    <td>{{jt.name}}</td>
                    <td style="text-align:center;">
                      <b-dropdown dropleft size="lg" class="card-drop" variant="link" toggle-class="text-decoration-none p-0" no-caret>
                        <template #button-content >
                          <i class="fas fa-ellipsis-h" />
                        </template>

                        <b-dropdown-item @click="editJt(jt)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> {{$t('general.edit')}}
                        </b-dropdown-item>

                        <b-dropdown-item @click="showDeletionModal(jt.idPublic, index)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> {{$t('general.delete')}}
                        </b-dropdown-item>
                      </b-dropdown>                      
                    </td>                      
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletionModal ref="jobTypeDeleteModal" :content="this.$t('general.deleteWorkorderTypeContent')" :title="this.$t('general.deleteWorkorderTypeTitle')"/>
    <JobTypeDetailModal/>
  </Layout>
</template>
